import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import MKBox from "components/MKBox";

const CountdownTimer = () => {
  const calculateTimeLeft = () => {
    let year = new Date().getFullYear();
    const difference = +new Date(`${year}-07-25`) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <MKBox
        color="white"
        bgColor="dark"
        variant="gradient"
        borderRadius={15}
        shadow="md"
        opacity={1}
        p={2}
        width="8rem"
      >
        <Stack
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ px: 2 }}
        >
          <h1 style={{ fontFamily: "arial" }}>
            {timeLeft[interval]}
          </h1>
          <Typography
            variant="p"
            component="div"
            key={interval}
            style={{ fontFamily: "monospace" }}
          >
            {"["}{interval}{"]"}
          </Typography>
        </Stack>
      </MKBox>
    );
  });

  return (
    <MKBox display="flex" justifyContent="center" alignItems="center">
      <Stack direction="row" spacing={2} sx={{ mb: 8, mt: 8 }}>
        {timerComponents.length ? (
          timerComponents
        ) : (
          <Typography variant="h6">It's Time!</Typography>
        )}
      </Stack>
    </MKBox>
  );
};

export default CountdownTimer;
