// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "pages/Header/DefaultNavbar";
import DefaultFooter from "pages/Footer/DefaultFooter";

// About Us page sections
import Information from "pages/LandingPages/AboutUs/sections/Information";
import CountdownTimer from "./sections/Countdown";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/construction2.jpeg";

function AboutUs() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.nullsecurity.tech",
          label: "Check Out Null-Security E.O.D",
          color: "forest",
        }}
        transparent
        relative
        dark
        center
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      ></MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -10,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKTypography
          display="flex"
          justifyContent="center"
          alignItems="center"
          variant="h3"
          color="black"
          sx={({ breakpoints, typography: { size } }) => ({
            [breakpoints.down("md")]: {
              fontSize: size["3xl"],
            },
          })}
        >
          Something New Is Coming...
        </MKTypography>
        <MKBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          px={5}
          py={2}
        >
          <MKTypography
            variant="body1"
            color="text"
            style={{ textAlign: "center" }}
          >
            After years of paying to host a basic website that didn't quite meet
            our needs, we've decided to finally
            <br />
            make the transition to taking a more hands on approach!
          </MKTypography>
        </MKBox>
        <Information />
        <MKBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          px={5}
          py={2}
        >
          <MKTypography
            variant="h5"
            color="text"
            style={{ textAlign: "center" }}
          >
            Our original site is still available for viewing while 
            we continue this page's development.
            <br />
            Anyone interested in learning a bit more about our services and who we are, 
            are encouraged to <a href="https://nullreturn-it.wixsite.com/x00x0d">check it out</a> to learn more!
          </MKTypography>
        </MKBox>
        <CountdownTimer />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default AboutUs;
